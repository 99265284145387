import React, { useState, useEffect } from "react";
import "./App.css";
import Quote from "./Components/Quote/Quote";

function App() {
  const [quotes, setQuotes] = useState(null);
  const [randomQuote, setRandomQuote] = useState(null);

  const handleQuotes = (data) => {
    setQuotes(data);
    setRandomQuote(
      Object.values(data)[
        Math.floor(Math.random() * Object.values(data).length)
      ]
    );
  };

  useEffect(() => {
    console.log("Click this link --> https://tinyurl.com/smnmfcvr");
    fetch("https://type.fit/api/quotes")
      .then((response) => response.json())
      .then((data) => handleQuotes(data));
  }, []);

  function getRrandomQuote() {
    let values = quotes && Object.values(quotes);
    let randomQuote =
      values !== null && values[Math.floor(Math.random() * values.length)];
    setRandomQuote(randomQuote);
  }

  return (
    <div className="App">
      <h1 className="title">
        Random Quote<span>By Robert</span>
      </h1>
      <Quote randomQuote={randomQuote} getRrandomQuote={getRrandomQuote} />
    </div>
  );
}

export default App;
