import React from "react";
import "./Quote.css";

const Quote = ({ randomQuote, getRrandomQuote }) => {
  return (
    <div className="quoteContainer">
      <h2>
        <span>" </span>
        {randomQuote !== null && randomQuote.text}
        <span> "</span>
      </h2>
      <p>
        ~{" "}
        {randomQuote !== null && randomQuote.author !== null
          ? randomQuote.author
          : "Unknown"}{" "}
        ~
      </p>
      <button onClick={() => getRrandomQuote()}>REFRESH</button>
    </div>
  );
};

export default Quote;
